<template>
<div id='question_app' style="padding-bottom: 10vh;">
<div class="q_body" :style="{width:panelWidth||'94%'}">
  <div class="q_item" v-for="(item,index) in questions" :key="index">
    <div class="edit" v-show="item.show" @click.stop="ShowOptions(item)">
      修改
    </div>
    <div class="tit">
       {{item.label + (item.controlType==='checkbox'?'（多选）':'')}}
    </div>
    <div v-show="!item.show">
      <div class="options" v-if="item.controlType==='radio'">
        <div v-for="(option,i) in item.items" :key="i" 
            :class="{option_item:true,'active_option':ShowActiveClass(item,option.id)}" 
            @click.stop="ActiveOption(item,option)">
            {{option.label}}
        </div>
      </div>
      <div class="options" v-else-if="item.controlType==='checkbox'">
          <div v-for="(option,i) in item.items" :key="i" 
            :class="{checkbox_item:true, option_item:true,'active_option':ShowActiveClass(item,option.id)}"
            @click.stop.prevent="CheckboxActiveOption(item,option)">
              {{option.label}}
          </div>
          <div @click.stop.prevent="NextQuestionItem(item)" class="next_btn">
            下一题
          </div>
      </div>
    </div>
    <div v-show="item.show">
      {{GetOptionText(item)}}
    </div>
  </div>
</div>
<div class="btn_panel" v-show="confirm&&!hideConfirm" @click.stop="Submit">
  <span>提交问卷</span>
</div>
</div>
</template>

<script>

import { RadioGroup, Radio,Field } from 'vant';
export default {
    components:{
      [RadioGroup.name]: RadioGroup,
      [Radio.name]: Radio,
      [Field.name]: Field,
    },
    props:{
      panelWidth:{
        type:String,
        default:''
      },
      questionList:{
        type:Array,
        default:()=>[]
      },
      hideConfirm:{
        type:Boolean,
        default:false
      }
    },
    data(){
      return {
        questions:[],
        isActive:false,
        confirm:false,
      };
    },
    watch:{
      questionList(){
        if(this.questionList && this.questionList.length>0){
          var item = this.questionList[0];
          this.questions = [JSON.parse(JSON.stringify(item))]
        }
      }
    },
    methods:{
      CheckboxActiveOption(item,option){
        var id = option.id;
        var deductionId = option.deductionId;
        //提示
        if(option.tips){
          this.$toast.fail(option.tips);
        }
        if(!item.value){
            this.$set(item,"value",[{id:id,deductionId:deductionId,deduction:option.deduction,isRepair:!!option.isRepair}]);
        }else{
          var index = item.value.findIndex(e=>e.id === id);
          if(index > -1){
            item.value.splice(index,1);
          }else{
            item.value.push({id:id,deductionId:deductionId,deduction:option.deduction,isRepair:!!option.isRepair});
          }
        }
      },
      ActiveOption(item,option){        
        var id = option.id;
        var deductionId = option.deductionId;
        if(!item.value){
          this.$set(item,"show",true);
          this.$set(item,"value",[{id:id,deductionId:deductionId,deduction:option.deduction,isRepair:!!option.isRepair}]);
        }else{
          item.value = [{id:id,deductionId:deductionId,deduction:option.deduction,isRepair:!!option.isRepair}]
          item.show = !item.show
        }
        if(option.tips){
          this.$toast.fail(option.tips);
        }else{
          this.NextQuestionItem(item,option);
        }
      },
      NextQuestionItem(item,option){
       if(!item.value||item.value.length==0){
         this.$toast.fail("请至少选择一项");
         return
       }
        var showId = option&&option.showId;
        if(item.controlType === 'checkbox'){
          //如果Tips属性有值，则阻止提交
          var tipIndex = item.items.findIndex(e=>{
              var index = item.value.findIndex(a=>a.id===e.id&&e.tips);
              return index > -1
          })
          if(tipIndex>-1){
            var tips =  item.items[tipIndex].tips;
            this.$toast.fail(tips);
            return
          }

          //复选框所有的选项ShowId都一致，所以取第一个
          showId = item.items[0].showId
          if(item.show===undefined){
            this.$set(item,"show",true);
          }else{
            item.show = !item.show
          }
        }
        //如果选择的选项变了，那么选项对应的下一个问题也需要进行改变
        var index = this.questions.findIndex(e=>e.id == item.id);
        if(index<this.questions.length-1){
          this.questions.splice(index+1,this.questions.length-index-1);
        }
        // index+=1;
        // debugger
        // for(;index<this.questions.length;index++){
        //   if(this.questions[index].id == showId){
        //     if(this.confirm){
        //       this.$emit("confirm",this.GetFormData());
        //     }
        //     return
        //   }else{
        //     this.questions.splice(index,1);
        //   }
        // }
        if(this.confirm){
          this.$emit("confirm",this.GetFormData());
        }
        //避免重复添加问题
        index = this.questions.findIndex(e=>e.id == showId);
        if(index>-1){
          return;
        }
        //根据showId查找问题并添加
        var questionItem = this.questionList.find(e=>{
          return e.id == showId;
        })
        if(questionItem){
          this.questions.push(JSON.parse(JSON.stringify(questionItem)));
          this.confirm=false
        }else{
          this.confirm=true
          this.$emit("confirm",this.GetFormData());
        }
      },
      GetOptionText(item){
        if(item.value && item.value.length > 0){
            var labels = item.items.filter(e=> {
              var index = item.value.findIndex(p=>e.id === p.id);
              return index>-1;
            });
          return (labels.map(e=>e.label)).join(",");
        }
      },
      ShowOptions(item){
        item.show=!item.show;
      },
      ShowActiveClass(item,id){
         var flag = item.value&&(item.value.findIndex(e=>e.id == id)>-1);
         return flag;
      },
      GetFormData(){
        var formData = [];
        this.questions.forEach(e=>{
          formData.push({id:e.id,value:e.value,standardId:e.standardId,typeId:e.typeId,isLeaf:e.isLeaf});
        })
        return formData;
      },
      Submit(){
        var formData = this.GetFormData()
        this.$emit('subCustomerPrice',formData);
      },
    },
    created(){      
    }
}
</script>

<style scoped>
.q_body{
  padding-top: 10px;
}
.q_item{
  position: relative;
  background-color: white;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
}
.options .option_item{
  width: 94%;
  margin: 0px auto;
  height: 40px;
  text-align: center;
  line-height: 40px;
  background-color: white;
  margin-top: 4px;
  border-radius: 10px;
  border: 1px solid #eaeaea;
}
.options .checkbox_item{
  width: 47%;
  display: inline-block;
  margin-left: 2%;
}
.q_body{
  width: 94%;
  margin: 0px auto;
}
.tit{
  min-height: 30px;
  /* line-height: 30px; */
  font-weight: bold;
}
.options .active_option{
  border:1px solid #4600af;
}
.edit{
  position: absolute;
  right: 5px;
  top: 10px;
  background-color: #cecece;
  padding: 0px 5px;
  border-radius: 10px;
  color: white;
}
.btn_panel{
  width: 94%;
  margin: 0px auto;
  height: 50px;
  line-height: 50px;
  background-color: #4600af;
  color: white;
  position: fixed;
  bottom: 0px;
  text-align: center;
  border-radius: 10px;
  left: 3%;
  right: 3%;
}
.next_btn{
  width: 94%;
  margin:0px auto;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 10px;
  background-color: #4600af;
  color: white;
  margin-top: 10px;
}
</style>
